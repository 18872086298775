/* faq.css */

.faq-item {
    border: 1px solid #e5e7eb;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    padding: 1rem;
  }
  
  .faq-question {
    font-size: 1.2rem;
    font-weight: 500;
    cursor: pointer;
  display: flex;
  justify-content: space-between;
  

  }
  
  .faq-question.active {
    color: #fdfdfd; /* You can change this color to your desired active color */
  }
  
  .faq-answer {
    margin-top: 1rem;
    font-size: 1rem;
    color: #fdfeff;
  }
  .faq-item.active .faq-question .arrow {
    transform: rotate(90deg);
    transition: transform 0.3s ease;
  }
  
  /* Add more styles as needed */
  