.portfolio__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.portfolio__item {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 2rem;
    border: 2px solid transparent;
    transition: var(--transition);
}

.portfolio__item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
}

.portfolio__item-image {
    border-radius: 1.5rem;
    overflow: hidden;
}

.portfolio__item h3 {
    margin: 1.2rem 0 2rem;
}

.portfolio__item-cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    gap: 0.5rem;
}

.pagination .btn {
    padding: 1rem 1.5rem;
    font-size: 1rem;
    border: none;
    background: #f0f0f010;
    border-radius: 50%;
    cursor: pointer;
    transition: background 0.3s ease;
}

.pagination .btn:hover {
    background: #d0d0d0;
    border-color: var(--color-primary-variant);
    border: 2px solid;
}

.pagination .btn-primary {
    background: #007bff;
    color: white;
    border-color: var(--color-primary-variant);
    border: 2px solid;
}

.pagination .btn-primary:hover {
    background: #0056b3;
}

.share-button-container {
    position: relative;
}

.share-options {
    margin-top: 10%;
    display: flex;
    gap: 10px;
    position: absolute;
    top: 100%;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
    transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
}

.share-button-container:hover .share-options {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

.share-options button {
    color: aliceblue;
    background: none;
    font-size: 1.5rem;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
}

.share-options button:hover .fa-linkedin {
    color: #0077b5;
    /* LinkedIn Blue */
}

.share-options button:hover .fa-whatsapp {
    color: #25d366;
    /* WhatsApp Green */
}

.share-options button:hover .fa-twitter {
    color: #1da1f2;
    /* Twitter Blue */
}

.share-options button:hover .fa-pinterest {
    color: #e60023;
    /* Pinterest Red */
}

.category-buttons {
    display: flex;
    flex-wrap: wrap; /* Allow buttons to wrap onto the next line */
    gap: 10px;
    margin-bottom: 20px;
    
  }
  
  .category-buttons .btn {
    padding: 10px 20px;
    cursor: pointer;
    color: black;
    /* flex: 1 1 auto; Allow buttons to grow and shrink as needed */
  }
  
  .category-buttons .btn-primary {
    background-color: #007bff;
    color: #fff;
  }
  

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}


/* Media query (Medium Devices)*/
@media screen and (max-width: 1024px) {
    .portfolio__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}


/* Media query (Small Devices)*/
@media screen and (max-width: 600px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
    .category-buttons .btn {
        flex: 1 1 45%; /* Make buttons take the full width on small screens */
      }
}