.portfolio__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.portfolio__item {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 2rem;
    border: 2px solid transparent;
    transition: var(--transition);
}

.portfolio__item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
}

.portfolio__item-image {
    border-radius: 1.5rem;
    overflow: hidden;
}

.portfolio__item h3 {
    margin: 1.2rem 0 2rem;
}

.portfolio__item-cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    gap: 0.5rem;
  }
  
  .pagination .btn {
    padding: 1rem 1.5rem;
    border: none;
    background: #f0f0f010;
    border-radius: 50%;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .pagination .btn:hover {
    background: #d0d0d0;
    border-color: var(--color-primary-variant);
    border: 2px solid;
  }
  
  .pagination .btn-primary {
    background: #007bff;
    color: white;
    border-color: var(--color-primary-variant);
    border: 2px solid;
  }
  
  .pagination .btn-primary:hover {
    background: #0056b3;
  }

/* Media query (Medium Devices)*/
@media screen and (max-width: 1024px) {
    .portfolio__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}


/* Media query (Small Devices)*/
@media screen and (max-width: 600px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}