.wrappingimage-left {
  float: left;
  margin: 7px 12px 0 4px;
  width: 50%;
}

.wrappingimage-right {
  float: right;
  margin: 7px 4px 0 12px;
  width: 50%;
}

.responsive-iframe {
  position: relative;
  overflow: hidden;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(78, 3, 3, 0.17);
}

.responsive-iframe iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.details__containerr img {
  border-radius: 0.5rem;
  margin-bottom: none;
}

.details__containerr h2 {
  font-family: var(--subtitle-font-name);
}

.details__containerr p {
  text-align: justify;
  font-size: 1.2rem;
}

.details__containerr li {
  font-size: 1.2rem;
}


@keyframes borderAnimation {
  0% {
    border-color: red;
    box-shadow: 0 0 5px red;
  }

  25% {
    border-color: blue;
    box-shadow: 0 0 5px blue;
  }

  50% {
    border-color: green;
    box-shadow: 0 0 5px green;
  }

  75% {
    border-color: yellow;
    box-shadow: 0 0 5px yellow;
  }

  100% {
    border-color: red;
    box-shadow: 0 0 5px red;
  }
}

.contain {
  display: table;
}

.contain h3 {
  padding: 10px 15px;
  border-radius: 20px;
  display: table-cell;
  border: 2px solid transparent;
  background-color: #f0f0f0;
  animation: borderAnimation 4s infinite;
}

.centered-title {
  text-align: center;
}

.details__info {
  border-radius: 0.5rem;
  border: 1px solid rgb(229, 231, 235);
  padding: 2rem;
}

/* Style the accordion container */
details {
  border: 1px solid #ed5b21;
  margin-bottom: 1rem;
  border-radius: 4px;
  background-color: #f9f9f9;
}

/* Style the summary element (the clickable heading) */
summary {
  font-weight: bold;
  padding: 1rem;
  cursor: pointer;
  user-select: none;
  background-color: #e1e1e1;
  border-bottom: 1px solid #ccc;
}

/* Style the summary when it's open */
details[open] summary {
  background-color: #ccc;
}

/* Style the content within the accordion */
details p {
  padding: 1rem;
}

/* Style the accordion when it's open */
details[open] {
  border: 1px solid #ccc;
  background-color: #fff;
}



@media only screen and (max-width: 768px) {
  .details__containerr img {
    margin-bottom: 0;
  }

  .details__containerr p {
    text-align: left;
    font-size: 1.2rem;
  }
  .wrappingimage-left, .wrappingimage-right {
    float: none;
    width: 100%;
    margin: 1rem 0 1rem 0;
  }
}